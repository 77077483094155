import React from "react";
import useTranslation from "../hooks/useTranslation";

import { qaStrings, qaQuestions as questions } from "../strings";

const QAEntry = (input) => {
    const strings = useTranslation(input);
    return <>
        <dt>{strings.q}</dt>
        <dd>{strings.a}</dd>
    </>;
};

function QandA(props) {
    const strings = useTranslation(qaStrings);
    return <>
        <p>{strings.intro}</p>
        <dl id="qa">
            {questions.map(input => 
                <QAEntry key={input.q.da}
                    {...input} />
            )}
        </dl>
    </>;
}

export default QandA;