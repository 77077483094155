import React from "react";

function shuffle(array) {
    let currentIndex = array.length;
    while (currentIndex !== 0) {
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
}

function Emoji(props) {
    const {icon, label} = props;
    return (
        <span className="emoji" role="img" label={label}>{icon}</span>
    );
}

export {shuffle, Emoji};