import { useContext, useMemo } from "react";
import { LanguageContext } from "../LanguageProvider";

function useTranslation(input) {
    const {language} = useContext(LanguageContext);
    const object = useMemo(() => {
        let translation = {};
        for(const [key, object] of Object.entries(input)) {
            translation = {...translation, [key]: object[language]};
        }
        return translation;
    }, [language, input]);
    return object;
}

export default useTranslation;