import React, { createContext, useEffect, useState } from "react";

const LanguageContext = createContext({
    language: 'en'
});

function LanguageProvider({ children }) {
    const [language, setLanguage] = useState('en');
    useEffect(() => {
        if(window.navigator.languages.includes('da')) {
            setLanguage('da');
        } else {
            setLanguage('en');
        }
    }, []);
    function handleChangeLanguage(lang) {
        setLanguage(lang);
    }
    return (
        <LanguageContext.Provider value={{language, handleChangeLanguage}}>
            {children}
        </LanguageContext.Provider>
    );
}

export default LanguageProvider;
export {LanguageContext};