import React from "react";

import {Route} from 'react-router-dom';

import views from "./components/views";
import Header from "./components/elements/Header";

function App() {
    return (
        <div>
            {views.map((view, n) => (
                <Route {...view.routeProps}
                    key={view.name}
                    render={props => <>
                        <Header />
                        <view.component
                            {...props} />
                    </>} />
            ))}
        </div>
    );
}

export default App;
