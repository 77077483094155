import React, { useState } from "react";

function AccordionItem(props) {
    return <>
        <h2
            className={props.isActive? 'active' : 'hidden'}
            id={props.id}
            onClick={props.onHeaderClick}
        >
            {props.title}
        </h2>
        <section
            className={props.isActive? 'active' : 'hidden'}
        >
            {props.content}
        </section>
    </>;
}

function PageAccordion({ pages }) {
    const firstPageKey = pages[0].key;
    const [activeKey, setActiveKey] = useState(firstPageKey);
    return pages.map(page => (
        <AccordionItem
            key={page.key}
            id={page.key}
            isActive={page.key === activeKey}
            title={page.title}
            content={page.content}
            onHeaderClick={() => {
                setActiveKey(page.key);
                setTimeout(() => {
                    document.getElementById(page.key).scrollIntoView({
                        behavior: 'smooth'
                    });
                }, 1000);
            }} />
    ));
    // return <>
    //     pages.map(({ title, content }) => <>
    //         <h1>{title}</h1>
    //         {content}
    //     </>);
    // </>;
}

export default PageAccordion;