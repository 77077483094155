import React from "react";

import useTranslation from "../hooks/useTranslation";
import { Emoji } from "../util";
import { locationStrings } from "../strings";

function Location() {
    const strings = useTranslation(locationStrings);
    return <>
        <p>{strings.scheduleDisclaimer}</p>
        <dl id="schedule">
            <dt>13:30</dt>
            <dd>
                <Emoji label={strings.weddingCeremony} icon="💍"></Emoji>
                {strings.weddingCeremony}
            </dd>
            <dt>15:00</dt>
            <dd>
                <Emoji label={strings.reception} icon="🥂"></Emoji>
                {strings.reception}
            </dd>
            <dt>18:00</dt>
            <dd>
                <Emoji label={strings.dinner} icon="🍽️"></Emoji>
                {strings.dinner}
            </dd>
            <dt>22:00</dt>
            <dd>
                <Emoji label={strings.party} icon="🥳"></Emoji>
                {strings.party}
            </dd>
        </dl>
        <p>{strings.locationCeremony} {strings.locationParty}</p>
        <div className="full-width">
            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1_DACB8SJR0wipNJ-QnVE-p-rFVEUTQo&ehbc=2E312F&noprof=1&z=10" width="100%" height="350" title="Map"></iframe>
        </div>
    </>;
}

export default Location;