import React, { useContext, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import useTranslation from '../hooks/useTranslation';
import { LanguageContext } from '../LanguageProvider';
import { Emoji, shuffle } from '../util';
import { headerStrings } from '../strings';

function Header(props) {
    const strings = useTranslation(headerStrings);
    const {language, handleChangeLanguage} = useContext(LanguageContext);
    const toggleLanguage = (lng) => {
        handleChangeLanguage(lng);
    };
    const carouselProps = {
        autoPlay: true,
        customLeftArrow: <></>,
        customRightArrow: <></>,
        infinite: true,
        responsive: {
            mobile: {
                breakpoint: { max: 4000, min: 0},
                items: 1
            }
        },
        swipeable: true
    };
    const carouselItems = [
        <img key="agave" src="/img/agave.jpg" alt="Ralph og Tine i agavemarkerne i Mexico" />,
        <img key="budapest" src="/img/budapest.jpg" alt="Ralph og Tine på en af deres første rejser i Budapest" />,
        <img key="dykkere" src="/img/dykkere.jpg" alt="Ralph og Tine dykker i cenote på Yucatán-halvøen, Mexico" />,
        <img key="georgien" src="/img/georgien.jpg" alt="Ralph og Tine på vandretur i Georgien" />,
        <img key="pubcrawl" src="/img/header.jpg" alt="Ralph og Tine på pubcrawl i London" />,
        <img key="kirgisistan" src="/img/kirgisistan.jpg" alt="Ralph og Tine på vandretur i Kirgisistan" />,
        <img key="ski" src="/img/ski.jpg" alt="Ralph og Tine på skiferie" />,
        <img key="sdrvinter" src="/img/sdrvinter.jpg" alt="Ralph og Tine i vinterlandskabet i Sdr. Vissing" />,
        <img key="tallinn" src="/img/tallinn.jpg" alt="Ralph ligger på Tines hoved i Tallinn" />
    ];
    useEffect(() => shuffle(carouselItems));
    return (
        <header>
            { language !== 'en' ?
                <small onClick={() => toggleLanguage('en')}><Emoji label="English" icon="🇬🇧" /></small> : ''}
            { language !== 'da' ?
                <small onClick={() => toggleLanguage('da')}><Emoji label="Danish" icon="🇩🇰" /></small> : ''}
            { language !== 'lol' ?
                <small onClick={() => toggleLanguage('lol')}><Emoji label="Llama lol" icon="🦙" /></small> : ''}
            <h1>
                Tine &amp; <span>Ralph</span>
            </h1>
            <div>
                <Carousel {...carouselProps}>
                    {carouselItems}
                </Carousel>
            </div>
            <p>
                {strings.saturday} 29/3/2025
                <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.3621 0.000156228C17.1949 0.000156228 19.5 2.18552 19.5 4.89097C19.5 8.97996 16.4483 12.9603 10.4585 16.8692C10.3243 16.9577 10.1714 17 10.0206 17L10 16.999L9.97831 17C9.84302 17 9.70568 16.9658 9.58174 16.8944L9.54043 16.8692C3.55067 12.9603 0.5 8.97996 0.5 4.89097C0.5 2.18541 2.80507 0.000154754 5.63794 0.000154754C6.78426 0.000154754 7.87691 0.360346 8.76818 1.01234C9.11827 1.26891 9.18849 1.75387 8.92516 2.09495C8.66284 2.43603 8.16507 2.50444 7.81498 2.24789C7.19741 1.79512 6.43836 1.54559 5.63798 1.54559C3.67166 1.54559 2.08631 3.04876 2.08631 4.89096C2.08631 8.25256 4.66607 11.7198 9.92779 15.2573L10.0001 15.3056L10.0724 15.2573C15.2793 11.7558 17.8602 8.32494 17.9128 4.99553L17.9138 4.8909C17.9138 3.04865 16.3286 1.54552 14.3622 1.54552C12.842 1.54552 11.5098 2.45205 11.013 3.77514C10.8622 4.17559 10.4068 4.38185 9.99575 4.23496C9.58369 4.08906 9.37301 3.64534 9.52275 3.24389C10.2488 1.31208 12.1767 0.000156228 14.3621 0.000156228Z" fill="#D1ABA1"/></svg>
            </p>
        </header>
    );
}

export default Header;